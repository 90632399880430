.qr-code-show{
  border-radius: 5px;
  border: 1px solid #bbb;
  .qr-code-title{
    font-weight: 700;
    padding: .3rem 0;
  }
  .qr-code-show-image{
    position: relative;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #bbb;
    .logo{
      position: absolute;
      width: 50px;
      height: 50px;
      object-fit: contain;
      object-position: center center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      
    }
  }
}